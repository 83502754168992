import 'jquery';
import 'jquery-ui-dist/jquery-ui';
import 'moment-timezone';
import 'bootstrap';
import 'bootstrap-daterangepicker';
import 'jquery-popup-overlay';
import 'jquery-sortable';
import 'jquery-validation';

// fullcalendar jQuery library needs to be set up at a place inside "zf"
// Extending the global jQuery instance from the vue3 folder does not work
import 'fullcalendar';
import './vendor/fullcalendar/locale';

import 'jstree';

import Highcharts from 'highcharts';
import addMore from 'highcharts/highcharts-more';
import addExporting from 'highcharts/modules/exporting';
import addExportData from 'highcharts/modules/export-data';
import addOfflineExporting from 'highcharts/modules/offline-exporting';
import addHeatmap from 'highcharts/modules/heatmap';
import addBoost from 'highcharts/modules/boost';
import addAnnotations from 'highcharts/modules/annotations';
import jsPDF from 'jspdf';
import svg2pdf from 'svg2pdf.js';
import BrowserDetectionService from './common/browserDetection/BrowserDetectionService';
import EvaluationEvent from './vcom/events/EvaluationEvent';
import TechnicalDataEvent from './vcom/events/TechnicalDataEvent';
import EventService from './common/event/EventService';
import TimeInMilliseconds from './common/time/TimeInMilliseconds';

window.VCOM = {};
window.jsPDF = jsPDF;
window.svg2pdf = svg2pdf;
window.EventService = EventService;
window.BrowserDetectionService = BrowserDetectionService;
window.EvaluationEvent = EvaluationEvent;
window.TechnicalDataEvent = TechnicalDataEvent;

addMore(Highcharts);
addExporting(Highcharts);
addExportData(Highcharts);
addOfflineExporting(Highcharts);
addHeatmap(Highcharts);
addBoost(Highcharts);
addAnnotations(Highcharts);

$.ajaxSetup({ timeout: TimeInMilliseconds.MINUTE * 4 });

// Prevent naming conflicts between jQuery UI and Bootstrap
const bootstrapButton = $.fn.button.noConflict();
$.fn.bootstrapButton = bootstrapButton;
const bootstrapTooltip = $.fn.tooltip.noConflict();
$.fn.bootstrapTooltip = bootstrapTooltip;
